<template>
  <div class="addCarType">

    <!-- Form -->
    <el-dialog width="600px" :visible="true" :before-close="cancelBtn" :close-on-click-modal="true">
      <div slot="title">
        <div class="addTitle">{{ tType == 'CH' ? '购买须知' : 'Purchase Instructions' }}</div>
      </div>

      <div>
        <div v-html="item"></div>
      </div>

    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "addCarType",
  props:['item','state','closeAlert'],
  data(){
    return{
      form: {

      },
      formLabelWidth: '120px',
      tType: 'CH'
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    console.log(this.item)
  },
  methods:{
    // 关闭弹窗
    cancelBtn(){
      this.$emit('closeAlert',false)
    }
  }
}
</script>

<style scoped lang="less">

</style>
