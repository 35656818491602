<template>
  <div>
    <div class="right">
<!--      <div class="right-img" id="map">
      <div class="right-img">
        <img src="@/assets/img/youchuan.png" width="100%" height="100%" alt="">
      </div>-->
      <div class="r_img">
        <img src="../../assets/img/YCbg.jpg" alt="">
        <div class="r_imgTitle" v-if="tType == 'CH'">
          古运河游船路线
        </div><div class="r_imgTitle" v-if="tType == 'EN'">
          Ancient Canal cruise route
        </div>
      </div>
      <div class="timelineBox">
        <div class="img">
          <img src="../../assets/img/YCtimeline.png" alt="">
        </div>

      </div>
      <div class="right-1">
<!--        <div class="right-1-1" v-for="(item) in list">
          <div class="right-1-1-1">
            <div class="right-1-1-1-1" v-if="tType == 'CH'">{{ item.type==1?'日场游船票':'夜场游船票' }}</div>
            <div class="right-1-1-1-1" v-if="tType == 'EN'">{{ item.type==1?'Matinee cruise tickets':'Night cruise tickets' }}</div>
          </div>
          <div class="right-1-1-2" style="display: flex;flex-wrap: wrap;">
            <div class="right-1-1-2-1" v-if="item.list==0&&tType == 'CH'" style="display: flex;align-items: center;justify-content: center;padding: 0">暂无票务信息</div>
            <div class="right-1-1-2-1" v-if="item.list==0&&tType == 'EN'" style="display: flex;align-items: center;justify-content: center;padding: 0">No ticket information at present</div>
            <div class="right-1-1-2-1" v-for="(items) in item.list">
              <div class="right-1-1-2-1-1">{{items.name}}
                <span v-if="items.limitType!=0&&tType == 'CH'">({{items.limitType==1?'成人':items.limitType==2?'儿童':items.limitType==3?'老人':''}})</span>
                <span v-if="items.limitType!=0&&tType == 'EN'">({{items.limitType==1?'adult':items.limitType==2?'children':items.limitType==3?'the aged':''}})</span>
              </div>
              <div class="right-1-1-2-1-2">{{ items.introduce }}</div>
              <div class="right-1-1-2-1-3">
                <span style="color: #007B8A" v-for="(i) in items.tags">{{i}}&nbsp;&nbsp;</span>
              </div>
              <div v-if="tType == 'CH'" style="font-size: 16px;margin: 20px 0;">
                数量:{{items.quantity}}
              </div>
              <div v-if="tType == 'EN'" style="font-size: 16px;margin: 20px 0;">
                quantity:{{items.quantity}}
              </div>
              <div class="right-1-1-2-1-4">
                <div class="right-1-1-2-1-4-1">￥&nbsp;{{items.actualPrice}}</div>
                <div @click="istan=true" class="right-1-1-2-1-4-2" v-if="tType == 'CH'">预约</div>
                <div @click="istan=true" class="right-1-1-2-1-4-2" v-if="tType == 'EN'">subscribe</div>
              </div>
            </div>
          </div>
        </div>-->

        <div class="right-1-1">
          <div class="right-1-1-2" style="display: flex;flex-wrap: wrap;">
            <div class="right-1-1-2-1" v-if="boolList.length==0&&tType == 'CH'" style="display: flex;align-items: center;justify-content: center;padding: 0">暂无票务信息</div>
            <div class="right-1-1-2-1" v-if="boolList.length==0&&tType == 'EN'" style="display: flex;align-items: center;justify-content: center;padding: 0">No ticket information at present</div>
            <div class="right-1-1-2-1" v-for="(items) in boolList">
              <div class="right-1-1-2-1-1" >{{ tType == 'CH' ? items.name || '&nbsp;' : items.enName || '&nbsp;' }}</div>
              <div v-if="tType == 'CH'" style="font-size: 16px;margin: 20px 0;">
                <span style="cursor: pointer" @click="goInfo(items.introduce)">购买须知 ></span>
              </div>
              <div v-if="tType == 'EN'" style="font-size: 16px;margin: 20px 0;">
                <span style="cursor: pointer" @click="goInfo(items.enIntroduce)">Purchase Instructions ></span>
              </div>
              <div class="right-1-1-2-1-4">
                <div class="right-1-1-2-1-4-1">￥&nbsp;{{items.actualPrice}}</div>
                <div @click="istan=true" class="right-1-1-2-1-4-2" v-if="tType == 'CH'">预约</div>
                <div @click="istan=true" class="right-1-1-2-1-4-2" v-if="tType == 'EN'">subscribe</div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="right-1-1">-->
<!--          <div class="right-1-1-1">-->
<!--            <div class="right-1-1-1-1">夜场游船票</div>-->
<!--            <div class="right-1-1-1-2">累计销量100+</div>-->
<!--          </div>-->
<!--          <div class="right-1-1-2">-->
<!--            <div class="right-1-1-2-1">-->
<!--              <div class="right-1-1-2-1-1">成人票</div>-->
<!--              <div class="right-1-1-2-1-2">身高150cm(不含)以上；补充说明：未</div>-->
<!--              <div class="right-1-1-2-1-3">-->
<!--                <span style="color: #007B8A">可定5月26日</span>&nbsp; <span style="color: #007B8A">随时退</span> &nbsp;无需取票</div>-->
<!--              <div>已售10 <span style="cursor: pointer">购买须知 ></span></div>-->
<!--              <div class="right-1-1-2-1-4">-->
<!--                <div class="right-1-1-2-1-4-1">￥&nbsp;90</div>-->
<!--                <div @click="istan=true" class="right-1-1-2-1-4-2">预约</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="right-1-1-2-1">-->
<!--              <div class="right-1-1-2-1-1">成人票</div>-->
<!--              <div class="right-1-1-2-1-2">身高150cm(不含)以上；补充说明：未</div>-->
<!--              <div class="right-1-1-2-1-3">-->
<!--                <span style="color: #007B8A">可定5月26日</span>&nbsp; <span style="color: #007B8A">随时退</span> &nbsp;无需取票</div>-->
<!--              <div>已售10 <span style="cursor: pointer">购买须知 ></span></div>-->
<!--              <div class="right-1-1-2-1-4">-->
<!--                <div class="right-1-1-2-1-4-1">￥&nbsp;90</div>-->
<!--                <div @click="istan=true" class="right-1-1-2-1-4-2">预约</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
<!--    <div class="paging-device">-->
<!--      <el-pagination-->
<!--        layout="prev, pager, next"-->
<!--        @size-change="handleSizeChange"-->
<!--        @current-change="handleCurrentChange"-->
<!--        :current-page="current_page"-->
<!--        :page-size="per_page"-->
<!--        :total="totalCount">-->
<!--      </el-pagination>-->
<!--    </div>-->
<!--    预约弹框-->
    <div v-show="istan" class="tan" @click="istan=false">
      <div class="tan-1">
<!--        <div class="tan1">-->
          <div class="tan-1-1" v-if="tType == 'CH'">线上预约</div>
          <div class="tan-1-1" v-if="tType == 'EN'">Online appointment booking</div>
          <div class="tan-1-2">
            <img src="https://zhongtai-media-test-1309102353.cos.ap-shanghai.myqcloud.com/huyunhe/16635864130001663586308162.jpg" width="100%" height="100%" alt="">
          </div>
          <div @click="istan=false" class="tan-1-3">
<!--            <i class="el-icon-circle-close tan-1-3-icon"></i>-->
            <img src="@/assets/img/guanbi.png" width="100%" height="100%" alt="">
          </div>
<!--        </div>-->
      </div>
    </div>

<!--    购买须知-->
    <Buyinfo v-show="isIf" v-if="isShow" :item="item" @closeAlert="closeAlert" ></Buyinfo>
  </div>
</template>

<script>
import {ticketList,getticketing, imgURL} from '@/api'
import TMap from "../../utils/initMap";
import Buyinfo from "./components/buyInfo";
import {retainedDecimalPlaces} from "@/utils/index"
export default {
  name: "sightseeing",
  components: {
    Buyinfo
  },
  data(){
    return {
      imgURL,
      tType:'CH',
      list:[
        {
          type:1,
          list:[]
        },{
          type:2,
          list:[]
        }
      ],
      totalCount: 0,
      current_page: 1,
      per_page: 4,
      istan:false,

      // 地图
      center: {lng: 0, lat: 0},
      zoom: 3,
      mapIcon:'https://ppt.chnlib.com/FileUpload/2018-11/7-11Zhang_Gao_1ing_Jia-110636_103.png',
      isIf: false,
      isShow: false,
      item: {},
      boolList: [],
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    this.initMap()
    this.createlist()
  },
  methods:{
    createlist(){
      ticketList({}).then(res=>{
        console.log(res);
        this.boolList = res.rows
        if(res.code==200){
          // this.list=res.rows
          this.list.forEach((item)=>{
            res.rows.forEach((items)=>{
              items.actualPrice = retainedDecimalPlaces(items.actualPrice)
              if(item.type==items.type){
                if(items.tags && items.tags.trim().length>0){items.tags = items.tags.split(',')}
                item.list.push(items)
              }
            })
          })
          console.log(this.list)

        }
      })
    },
    goInfo(item){
      this.item = item
      this.isShow = true
      setTimeout(()=>{
        this.isIf = true
      },100)

    },
    closeAlert(){
      this.isIf = false
      this.isShow = false
    },
    // 地图
    initMap(){
      //定义地图中心点坐标
      TMap.init().then((TMap) => {
        this.TXMap = TMap;
        this.tMap = new TMap.Map("map", {
          center: new TMap.LatLng(31.566088,120.30797), //设置地图中心点坐标
          zoom: 15, //设置地图缩放级别
          viewMode: "2D",
        });
        console.log(123)
        new TMap.MultiMarker({
          map:this.tMap,
          styles: {  // 点标记样式
            "myStyle": new TMap.MarkerStyle({
              "width": 48,
              "height": 52,
              "src": '/img/local.png',
              "anchor": { x: 10, y: 10 },
              "opacity": 1
            })
          },
          geometries: [{
            "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            "styleId": 'myStyle',  //指定样式id
            "position": new TMap.LatLng(31.566088,120.30797),  //点标记坐标位置
            "properties": {//自定义属性
              "title": "marker1"
            }
          }],
        })
      });

    },
  }
}
</script>

<style scoped lang="less">
.tan{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(27, 25, 25, 0.7);
  z-index:99;
  //.tan1{
    .tan-1{
      width: 350px;
      height: 100%;
      //background: red;
      margin: 0 auto;
      padding-top: 200px;
      box-sizing: border-box;
      .tan-1-1{
        font-size: 34px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        text-align: center;
      }
      .tan-1-2{
        width: 350px;
        height: 350px;
        margin: 51px 0 68px;
        border-radius: 20px;
        overflow: hidden;
      }
      .tan-1-3{
        width: 61px;
        height: 61px;
        margin: 0 auto;
        cursor: pointer;
        .tan-1-3-icon{
          font-size: 80px;
          color: #FFFFFF;
          //font-weight: 700;
        }
      }
    }
  //}
}
.right{
  .r_img{
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    z-index: 2;
    img{
      width: 100%;
      object-fit: cover;
    }
    .r_imgTitle{
      position: absolute;
      top: 0;
      //left: 50%;
      width: 100%;
      height: 100%;
      color: #FFFFFF;
      font-size: 40px;
      font-family: SimSun;
      font-weight: 700;
      z-index: 100;
      text-align: center;
      line-height: 300px;
      background: rgba(0, 123, 138, 0.5);
      z-index: 2;
    }
  }
  .timelineBox{
    width: 100%;
    height: 280px;
    position: relative;
    z-index: 5;
    .img{
      width: 1300px;
      height: 280px;
      margin: -100px auto 80px;
      z-index: 5;
      padding: 44px 0;
      background: #FFFFFF;
      border-radius: 40px;
      img{
        width: 100%;
        //height: 100%;
        object-fit: cover;
      }
    }

  }
  .right-img{
    width: 100%;
    height: 800px;
  }
  .right-1{
    width: 1230px;
    margin: 56px auto 190px;
    .right-1-1{
      margin-bottom: 60px;
      float: left;
      .right-1-1-1{
        display: flex;
        margin-bottom: 30px;
        margin-left: 15px;
        .right-1-1-1-1{
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 700;
          color: #333333;
        }
        .right-1-1-1-2{
          font-size: 14px;
          font-family: STSongti-SC-Bold, STSongti-SC;
          font-weight: bold;
          color: #333333;
          margin-left: 20px;
          margin-top: 20px;
        }
      }
      .right-1-1-2{
        .right-1-1-2-1{
          width: 380px;
          min-height: 210px;
          display: inline-block;
          background: #FFFFFF;
          border-radius: 20px;
          margin: 0 15px 15px 0;
          padding: 30px 0 24px 24px;
          .right-1-1-2-1-1{
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #333333;
          }
          .right-1-1-2-1-2{
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            margin: 10px 0;
            /* 文本不换行 */
            white-space: nowrap;
            /* 超出范围隐藏 */
            overflow: hidden;
            /* 文字超出用省略号 */
            text-overflow: ellipsis;
          }
          .right-1-1-2-1-3{
            margin: 10px 0;
          }
          .right-1-1-2-1-4{
            //padding-right: 20px;
            display: flex;
            justify-content: flex-end;
            .right-1-1-2-1-4-1{
              font-size: 34px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #007B8A;
            }
            .right-1-1-2-1-4-2{
              width: 80px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              background: #007B8A;
              border-radius: 5px 0px 0px 5px;
              margin-left: 20px;
              font-size: 14px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #FFFFFF;
              cursor: pointer;
            }
          }
        }
      }
    }
  }





}
/deep/.paging-device {
  margin: 70px 0 70px -50px;
  display: flex;
  justify-content: center;
}
</style>
